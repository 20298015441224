import React from "react"
import { Component, Fragment } from "react"
import TitleButton from "../../../general/titleButton"
import { Link } from "gatsby"
import CTA from "../../../general/cta"
import { scrollToElement } from "../../../../../utility/utils"

class FaqsBusiness extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 0,
            panelData: [],
            faqs: null,
            getLocalFaqs: [],
        }
        this.activateTab = this.activateTab.bind(this)
    }

    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index,
        }))
    }

    getdata = async firebase => {
        const data = await firebase.FireBase()
        this.setState({
            getLocalFaqs: firebase.getFaqs(),
        })
        return data
    }

    componentDidMount = async () => {
        setTimeout(() => {
            scrollToElement()
            window.addEventListener("scroll", function () {
                scrollToElement()
            })
        }, 0)

        import("../../../../../../firebase").then(firebase => {
            this.getdata(firebase)
        })

        this.setState({
            faqs: localStorage.getItem("kudaFaqs"),
        })
    }

    panels = []
    render() {
        if (this.state.faqs !== null) {
            this.panels = JSON.parse(this.state.faqs)
        } else {
            this.panels = this.state.getLocalFaqs
        }

        return (
            <Fragment>
                <div className="kuda-inner--heading kuda-disclaimer--section">
                    <div className="kuda-section--inner general-style">
                        <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                            <Link to="/en-ng/legal/" className="color-black mb-3 mt-4 back-page">
                                <div className="align-self-center mr-2">
                                    <div className="f-14">
                                        Legal
                                    </div>
                                </div>
                                <span className="mr-2">
                                    <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                            fill="#C7C7CC"
                                        ></path>
                                    </svg>
                                </span>
                                <span className="align-self-center f-14 mr-2">Nigeria</span>
                                <span className="mr-2">
                                    <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                            fill="#C7C7CC"
                                        ></path>
                                    </svg>
                                </span>
                                <span className="align-self-center f-14 text-bold text-left">Frequently Asked Questions</span>
                            </Link>
                            <div className="legal-left">
                                <h1 className="kuda-section--heading text-lg-biz mb-0 text-xlbold color-primary title-bottom--spacing no-margins">
                                    Kuda For Business FAQ's
                                </h1>
                            </div>
                        </div>
                        <div className="kuda-section--100 kuda-spacing--inner">
                            {/* <p className="color-black text-bold bottom--spacing">
                                Frequently Asked Questions
                            </p> */}
                            <p className="color-primary text-bold mb-3">
                                1. What kinds of accounts does Kuda Business offer?
                            </p>

                            <p className="color-black bottom--spacing">
                                Kuda Business offers two kinds of accounts:
                            </p>


                            <p className="color-black bottom--spacing">
                                <li className="text-bold ml-4">Freelancer Account
                                </li>
                                This kind of account is for individuals running very small-scale businesses or
                                freelancing, who need business banking services that can help them set up quickly.
                            </p>

                            <p className="color-black bottom--spacing">
                                <li className="text-bold ml-4">Full Business Account</li>
                                This kind of account is for a fully registered company running large-scale operations that
                                requires services to meet their extensive business needs.
                                Kuda currently serves Business Names and Limited Liability Companies.
                            </p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                2. What are the requirements for opening a Kuda Business account?
                            </p>
                            <p className="color-black bottom--spacing">
                                <li className="text-bold ml-4">Freelancer Account
                                </li>
                                To open a Freelancer account, you need the following:


                                <li className="ml-4">A photo of your face</li>
                                <li className="ml-4">Your BVN and a valid, government-issued ID</li>
                                <li className="ml-4">Detailed information about your business</li>
                                <li className="ml-4">Proof of address of your business</li>

                            </p>

                            <p className="color-black bottom--spacing">
                                <li className="text-bold ml-4">Full Business Account</li>
                                To open a Full Business account with just your business name, you need the following:


                                <li className="ml-4">Your FIRS Tax Identification Number (TIN)</li>
                                <li className="ml-4">Your CAC registration number</li>
                                <li className="ml-4">Certificate of Incorporation</li>
                                <li className="ml-4">CAC Registration Form - CAC BN/1</li>
                                <li className="ml-4">Proof of address of your business</li>

                            </p>

                            <p className="color-black bottom-spacing">
                                To open a Full Business account with as a Limited Liability Company, you need the following::

                                <li className="ml-4">Certificate of Incorporation</li>
                                <li className="ml-4">Memorandum and Articles of Association</li>
                                <li className="ml-4">Particulars of Directors - CAC 7/7A or CAC 1.1</li>
                                <li className="ml-4">Particulars of Shareholders - CAC 2/2A or CAC 1.1</li>
                                <li className="ml-4">Board Resolution - This document should mention the signatory (admin) who will use your Kuda Business account.</li>
                                <li className="ml-4">Proof of address of your company</li>

                            </p>
                        </div>


                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                3. How do I open a Freelancer account on my phone?
                            </p>

                            <p className="color-black mb-2 ml-2">1. Download the Kuda Business app on your app store. </p>
                            <p className="color-black mb-2 ml-2">2. Tap <span className="text-bold">Sign up with Kuda</span> if you have a personal Kuda account or tap <span className="text-bold">Sign up with email</span> if you don’t have a personal Kuda account.</p>
                            <p className="color-black mb-2 ml-2"> 3. Follow the prompts on your screen.</p>
                            <p className="color-black mb-2 ml-2">4. Tap <span className="text-bold">Freelancer Account</span> on the app dashboard.</p>
                            <p className="color-black mb-2 ml-2">5. Fill in the required details.</p>
                            <p className="color-black mb-2 ml-2">6. Review and submit your application.</p>

                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                4. How do I open a Freelancer account on a browser?
                            </p>

                            <p className="color-black mb-2 ml-2">1. Go to
                                <a href="https://business.kuda.com/get-started"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="color-primary link-decoration cursor-pointer ml-1"
                                >business.kuda.com/get-started</a></p>
                            <p className="color-black mb-2 ml-2">2. Click <span className="text-bold">Sign up with Kuda</span> if you have a personal Kuda account or click <span className="text-bold">Sign up with email</span> if you don’t have a personal Kuda account.</p>
                            <p className="color-black mb-2 ml-2"> 3. Follow the prompts on your screen.</p>
                            <p className="color-black mb-2 ml-2">4. Click <span className="text-bold">Freelancer Account.</span></p>
                            <p className="color-black mb-2 ml-2">5. Fill in the required details.</p>
                            <p className="color-black mb-2 ml-2">6. Review and submit your application.</p>

                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                5. How do I open a Full Business account on my phone?
                            </p>

                            <p className="color-black mb-2 ml-2">1. Download the Kuda Business app on your app store</p>
                            <p className="color-black mb-2 ml-2">2. Tap <span className="text-bold">Sign up with Kuda</span> if you have a personal Kuda account or tap <span className="text-bold">Sign up with email</span> if you don’t have a personal Kuda account.</p>
                            <p className="color-black mb-2 ml-2"> 3. Follow the prompts on your screen.</p>
                            <p className="color-black mb-2 ml-2">4. Tap <span className="text-bold"> Full Business</span> on the app dashboard.</p>
                            <p className="color-black mb-2 ml-2">5. Fill in the required details.</p>
                            <p className="color-black mb-2 ml-2">6. Review and submit your application.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                6. How do I open a Full Business account on a browser?
                            </p>

                            <p className="color-black mb-2 ml-2">1. Go to
                                <a href="https://business.kuda.com/get-started"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="color-primary link-decoration cursor-pointer ml-1"
                                >business.kuda.com/get-started</a></p>
                            <p className="color-black mb-2 ml-2">2. Click <span className="text-bold">Sign up with Kuda</span> if you have a personal Kuda account or click <span className="text-bold">Sign up with email</span> if you don’t have a personal Kuda account.</p>
                            <p className="color-black mb-2 ml-2"> 3. Follow the prompts on your screen.</p>
                            <p className="color-black mb-2 ml-2">4. Click <span className="text-bold">Full business.</span></p>
                            <p className="color-black mb-2 ml-2">5. Fill in the required details.</p>
                            <p className="color-black mb-2 ml-2">6. Review and submit your application.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                7. Why is my Full Business Account application pending after submission?
                            </p>
                            <p className="color-black">
                                Your application is pending because we’re reviewing your information. We’ll let you know if your application is approved or not in the next 72 working hours.
                            </p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                8. Can a business owner have more than one signatory to an account?
                            </p>
                            <p className="color-black">
                                A business account can only have one primary signatory/admin. This signatory or admin has to be the business owner, a director of your company, or
                                someone authorised by the company or business to run the account. The admin can add teammates to operate the account on their behalf.                            </p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                9. Can I change my personal Kuda account to a Kuda Business account?
                            </p>
                            <p className="color-black">
                                No, you can’t. You’ll need to open a separate business account. However, you can use your personal Kuda account credentials to open a
                                Kuda Business account much faster. Please, note that you’ll have to sign in to your personal and business accounts separately. </p>

                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                10. Can I make foreign transactions with my Kuda Business account?
                            </p>
                            <p className="color-black">
                                No, you can only make local transactions with your account. </p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                11. Can I be given a loan as a small business?
                            </p>
                            <p className="color-black">Yes, you can. Please, send your loan request to <a href="mailto:sales@kuda.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="color-primary text-bold link-decoration cursor-pointer"> sales@kuda.com.</a></p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                12. Can I open multiple Kuda Business accounts?
                            </p>
                            <p className="color-black mb-3">Yes, you can.</p>

                            <p className="color-black">Note: You can open just one Freelancer account, but you can open multiple Full Business accounts.</p>

                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                13. Does Kuda Business assign account officers to customers?
                            </p>

                            <p className="color-black">No, we don’t assign account officers to customers but our customer service team is available to attend to you.</p>

                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                14. Will I be charged a fee for opening a Kuda Business account?
                            </p>

                            <p className="color-black">No, opening a Kuda Business account is free, but you might need to pay a legal search fee when opening a Full Business account.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                15. Does Kuda charge an account maintenance fee on corporate accounts?
                            </p>
                            <p className="color-black"> No, we don’t charge an account maintenance fee at the moment.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                16. Can I use Kuda Business if I haven’t registered my business with the CAC?
                            </p>
                            <p className="color-black">You can open a Kuda Business Freelancer account if your business isn’t registered with the CAC.
                                To open a Full Business account, you’ll need to register your business name or company with the CAC.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                17. What are the minimum and maximum daily transaction limits?
                            </p>

                            <p className="color-black">There’s no minimum limit for transactions, but there’s a daily limit of 10 million naira on the Freelancer account.
                                The Full Business account has a daily transaction limit of 250 million naira.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                18. What are the minimum and maximum single transaction limits?
                            </p>
                            <p className="color-black">There’s no minimum single transaction limit, but there’s a limit of 5 million naira per transaction on the Freelancer account. The Full Business account has a limit of 25 million naira per transaction.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                19. Can I send money to more than one person at the same time?
                            </p>
                            <p className="color-black">Yes, you can. You can use the Multiple Transfer feature to send money to up to twenty people at once on the Kuda Business mobile and web apps.
                                If you need to send money to more than twenty people, you can use the Bulk Transfer feature on the Kuda Business web app.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                20. Can I pay salaries with Kuda Business?
                            </p>
                            <p className="color-black">Yes, you can use the Kuda Payroll feature or the Payroll with Bento feature to pay salaries.
                                Kuda Payroll currently supports payments to Kuda accounts exclusively while Payroll with Bento supports payments to all banks.
                                You can use these features only if you have a Full Business account.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                21. How do I add money to my Kuda Business account?
                            </p>
                            <p className="color-black">To add money to your Kuda Business account, make a transfer from any Nigerian bank account or use USSD.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                22. How do I change my Kuda Business password?
                            </p>
                            <p className="color-black">You can change your password easily by using the password reset option on the Kuda Business mobile or web app.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                23. What is a board resolution?
                            </p>
                            <p className="color-black">A board resolution is a resolution passed in a board meeting authorising certain people in a company to open and operate a bank account for the company.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                24. How do I submit a board resolution?
                            </p>
                            <p className="color-black">You’ll upload a copy of your board resolution on the Kuda Business mobile app or web app when you’re opening a Full Business account.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                25. Do I need to submit a reference to open a business account?
                            </p>
                            <p className="color-black">No, you do not.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                26. Can I update my company’s registered address on the Kuda Business app?
                            </p>
                            <p className="color-black">No, you can’t, but you can send an address update request to <a
                                href="mailto:business@kuda.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="color-primary text-bold link-decoration cursor-pointer"
                            >business@kuda.com.</a></p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                27. Can I change or update my company’s name on the Kuda Business app?
                            </p>
                            <p className="color-black">No, you can’t, but you can send a company name update request to <a
                                href="mailto:business@kuda.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="color-primary text-bold link-decoration cursor-pointer"
                            >business@kuda.com.</a></p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                28. How do I request my bank statement?
                            </p>

                            <p className="color-black mb-2 ml-2">1. Sign in to your Kuda Business app, then tap  <span className="text-bold">More.</span></p>
                            <p className="color-black mb-2 ml-2"> 2. Tap <span className="text-bold">Account Statement.</span></p>
                            <p className="color-black mb-2 ml-2">3. Choose a timeframe for your statement, then choose a format for the statement - a PDF file or an Excel spreadsheet.</p>
                            <p className="color-black mb-2 ml-2">4. Tap <span className="text-bold">Get</span> and we’ll send you your account statement by email.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                29. Can I add my employees or team members as users and what is the maximum number I can add?
                            </p>
                            <p className="color-black">Yes, as the account admin (primary signatory), you can add other users to your account. You can also manage the access level that other users have. There’s no limit to the number of users you can add.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                30. Can I create sub accounts on Kuda Business?
                            </p>
                            <p className="color-black">Yes, you can create up to five sub accounts at a time on Kuda Business. Sub accounts are useful for managing your finances - you can easily allocate money for different uses to different sub accounts.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                31. What is Kuda Business POS?
                            </p>
                            <p className="color-black">Kuda Business POS is a suite of products that help businesses get paid easily. It includes a physical POS terminal and Virtual POS - a dedicated account number that makes it easy for salespeople to confirm transfers instantly.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                32. What do I need to get access to Kuda Business POS?
                            </p>
                            <p className="color-black">To get access to Kuda Business POS, you need to open a Full Business account.</p>
                        </div>
                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                33. I have a Full Business account. How do I access Kuda Business POS?
                            </p>


                            <p className="color-black bottom--spacing">To access Kuda Business POS on the Kuda Business mobile app, follow the steps below:</p>

                            <p className="color-black mb-2 ml-2">1. Sign in to the Kuda Business mobile app.</p>
                            <p className="color-black ml-2 mb-4"> 2. Tap <span className="text-bold">Hub</span> on the app dashboard, then tap <span className="text-bold">POS.</span></p>


                            <p className="color-black mb-3">To access Kuda Business POS on the Kuda Business web app, follow the steps below:</p>

                            <p className="color-black mb-2 ml-2">1. Sign in to the Kuda Business web app <a href="https://business.kuda.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="color-primary link-decoration cursor-pointer ml-1"
                            >here.</a></p>
                            <p className="color-black mb-2 ml-2"> 2. Tap <span className="text-bold">Hub</span> on the app dashboard, then tap <span className="text-bold">POS.</span></p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                34. What is the POS Dashboard?
                            </p>
                            <p className="color-black">The POS Dashboard is a feature on the Kuda Business mobile and web apps for managing and monitoring your POS terminals, viewing your POS transaction history, and generating POS transaction reports.</p>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold mb-3">
                                35.  How do I track my sales with the POS Dashboard?
                            </p>
                            <p className="color-black">You can track your sales and monitor your business performance all on the POS dashboard.</p>
                        </div>

                        <TitleButton
                            text="Don't see your question?"
                            button="Visit our Help Center"
                            url="https://help.kuda.com/support/solutions/articles/73000559888-faqs"
                            isInnerSection={true}
                        />

                    </div>
                </div>
                <CTA />
            </Fragment >
        )
    }
}
export default FaqsBusiness
